<template>
    <div>
        <iframe width=100% height="1200px" :src="getFilePath" ></iframe>
    </div>
</template>

<script>
    export default {
        name: "KPdf",
        props: {
            src: String,
        },
        computed:{
            getFilePath ()  {
                return '/lib/pdfjs/web/viewer_v1.html?file=' + this.src
            }
        }
    }
</script>

<style scoped>
    div {
        /*width: 50%;*/
        /*height: 79vh;*/
        /*min-width: 400px;*/
    }
</style>