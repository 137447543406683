<template>
    <div class="MBcont" :class='$mq'>
        <h1>Материалы для создания будуарной куклы</h1>
        <p style="text-align:center; margin-bottom: 1em;"><a href="https://kibanovadolls.ru/marchbook">Создайте свою первую неповторимую куклу! Подробнее</a></p>
        <KPdf :src="getPdfSrc"/>
    </div>
</template>

<script>
    import KPdf from "../../Widgets/KPdf";


    export default {
        name: "Bjd",
        components: {
            KPdf,
        },
        computed: {
            getPdfSrc() {// eslint-disable-line
                // this.setClickHandler()
                let fullPath = "/files/Materials_book_begginners.pdf"

                if (this.doesFileExist(fullPath)) {
                    return fullPath
                } else {
                    this.$router.push("/404")// eslint-disable-line
                }
            }
        },
        methods: {
            setClickHandler() {
                if (!window.addEventListener) {
                    // IE8 support (could also use an addEventListener shim)
                    window.attachEvent('onmessage', this.handleMessage);
                } else {
                    window.addEventListener('message', this.handleMessage, false);
                }


            },
            handleMessage(event) {
                let data = event.data
                window.open(data)
            },
            doesFileExist(urlToFile) {
                var xhr = new XMLHttpRequest();
                xhr.open('HEAD', urlToFile, false);
                xhr.send();

                if (xhr.status == "404") {
                    return false;
                } else {
                    return true;
                }
            },


        },
    }
</script>
<style scoped>
    .MBcont {
        max-width: 900px;
        margin: 0 auto;
        border-top: 1px solid #e6e6e6;
        padding: 2em;
        min-height: 300px;
        position: relative;
    }

    .MBcont h1 {
        font-size: 35pt;
        line-height: 45pt;
        text-align: center;
        margin: 0 0 20px 0;
        font-weight: normal;
    }
    .MBcont.mobile h1{
        font-size: 24pt;
        line-height: 1;
        margin: 0 0 10pt 0;
    }
</style>